import { createSlice } from '@reduxjs/toolkit';
import { changeWithdrawalStatus, fetchBackofficeWithdrawalDetailById } from '../actions/withdrawalDetailActions';

// Initialisation de l'état initial
const initialState = {
  // Format du retour de l'API pour le détail d'un retrait
  withdrawal: {},
  loading: false,
  error: null,
  changeStatusLoading: false,
  changeStatusError: null,
};

// Création du slice pour le retrait
const withdrawalDetailSlice = createSlice({
  name: 'withdrawalDetail', // Nom du slice
  initialState, // État initial
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackofficeWithdrawalDetailById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBackofficeWithdrawalDetailById.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawal = action.payload;
      })
      .addCase(fetchBackofficeWithdrawalDetailById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Changement du statut du retrait en cours
      .addCase(changeWithdrawalStatus.pending, (state) => {
        state.changeStatusLoading = true;
        state.changeStatusError = null;
      })
      // Changement du statut du retrait terminé avec succès
      .addCase(changeWithdrawalStatus.fulfilled, (state, action) => {
        state.changeStatusLoading = false;
        state.withdrawal = action.payload;
      })
      // Changement du statut du retrait en échec
      .addCase(changeWithdrawalStatus.rejected, (state, action) => {
        state.changeStatusLoading = false;
        state.changeStatusError = action.payload;
      });
  },
});

export default withdrawalDetailSlice.reducer;
