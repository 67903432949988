import apiClient from '../api/apiClient';
import ENDPOINTS from '../api/endpoints';

/* eslint-disable implicit-arrow-linebreak */

/**
 * Requête API pour récupérer la liste des retraits
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getWithdrawals = async () => {
  const endpoint = ENDPOINTS.WITHDRAWALS;
  return apiClient.get(endpoint);
};

/**
 * Requête API pour récupérer le détail d'un retrait
 * @param withdrawalId Identifiant du retrait - dans les paramètres de l'URL
 * @param data le statut du retrait - dans le body de la requête
 * @returns {Promise<AxiosResponse<any>>}
 */
export const patchWithdrawalStatus = async (withdrawalId, data) => {
  const endpoint = ENDPOINTS.backofficeWithdrawalStatus(withdrawalId);
  return apiClient.patch(endpoint, data);
};

export const patchWithdrawalUpdate = async (withdrawalId, data) => {
  const endpoint = ENDPOINTS.backofficeWithdrawalUpdate(withdrawalId);
  return apiClient.patch(endpoint, data);
};
