import { createSlice } from '@reduxjs/toolkit';
import { updateWithdrawalNotification } from '../actions/withdrawalActions';
// import { insertSorted } from '../../utils/arrayUtils';
/* eslint-disable no-param-reassign */

const initialState = {
  orderWithdrawal: {},
  loading: false,
  error: null,
  isnotFound: false,
};

const orderNotificationSlice = createSlice({
  name: 'orderNotification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateWithdrawalNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateWithdrawalNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.orderNotification = action.payload;
      })
      .addCase(updateWithdrawalNotification.rejected, (state, action) => {
        state.loading = false;
        // Si le client a saisi un mauvais numéro de commande deux fois de suite
        // alors on lui affiche un message d'erreur spécifique
        state.error = (state.isnotFound && action.payload === 'Nous ne trouvons pas cette commande, veuillez vérifier votre saisie avant de recommencer.') ? 'Merci de vous diriger vers l\'interphone à droite de la porte pour signaler votre présence.' : action.payload;
        // A chaque erreur on garde dans state.isnotFound
        // le cas ou le message d'erreur était "commande non trouvée"
        state.isnotFound = (action.payload === 'Nous ne trouvons pas cette commande, veuillez vérifier votre saisie avant de recommencer.');
      });
  },
});

export default orderNotificationSlice.reducer;
