import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import echo from '../echo';
import { updateWithdrawalStatus } from '../store/actions/withdrawalActions';
import { socketEventEnd } from '../store/slices/socketSlice';

/**
 * Hook pour écouter la mise à jour du statut d'un retrait depuis un événement
 * @returns {null}
 */
const useWithdrawalUpdated = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const channel = echo.channel('italie2_database_update-withdrawal-status');

    channel.listen('WithdrawalStatusUpdatedEvent', (updatedWithdrawal) => {
      dispatch(updateWithdrawalStatus({ updatedWithdrawal }));
      dispatch(socketEventEnd());
    });

    return () => {
      dispatch(socketEventEnd());
      channel.stopListening('WithdrawalStatusUpdatedEvent');
      echo.leaveChannel('italie2_database_update-withdrawal-status');
    };
  }, [dispatch]);

  return null;
};

export default useWithdrawalUpdated;
