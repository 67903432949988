import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { changeWithdrawalStatus } from '../../store/actions/withdrawalDetailActions';
import { socketEventStart } from '../../store/slices/socketSlice';
import { getNextStatus, getNextStatusText } from '../../utils/withdrawalStatusUtils';
import { WithdrawalStatus } from '../../constants';

/* eslint-disable jsx-a11y/anchor-is-valid */
function StatusSection({
  status, withdrawals, isBackoffice, onOrderDetailClick,
}) {
  const dispatch = useDispatch();

  const { socketLoading } = useSelector((state) => state.socket);
  // Mise à jour du statut de la commande pour passer à l'étape suivante
  const handleUpdateToNextStatus = async (withdrawalId) => {
    const nextStatus = getNextStatus(status);
    const result = await dispatch(changeWithdrawalStatus({ withdrawalId, status: nextStatus }));

    if (changeWithdrawalStatus.fulfilled.match(result)) {
      dispatch(socketEventStart());
    }
  };

  const renderQrCodeTooltip = (withdrawal) => (
    <Tooltip id="tooltip-qrcode">
      <QRCodeCanvas value={withdrawal.number} />
    </Tooltip>
  );
  return (
    withdrawals && withdrawals.length > 0 ? (
      <section className={`status-${status}`}>
        <ul className="list-withdrawal-codes">
          {withdrawals.map((withdrawal) => (
            <li key={withdrawal.id} className={withdrawal.ready ? 'flag-clientReady' : ''}>
              <div className="wrapper">
                <div className="code">
                  {isBackoffice ? (
                    <ul className="code flags">
                      <li className={withdrawal.ready ? 'flag clientReady' : 'flag'}>
                        <span className="icon" />
                        <span className="text">Client arrivé</span>
                      </li>
                    </ul>
                  ) : null}
                  {isBackoffice ? (
                    <OverlayTrigger
                      overlay={renderQrCodeTooltip(withdrawal)}
                      placement="top"
                      trigger={['hover', 'focus']}
                    >
                      <Link
                        onClick={() => onOrderDetailClick(withdrawal.id)}
                        title={`Voir le détail de la commande ${withdrawal.number}`}
                        to=""
                      >
                        {withdrawal.number}
                      </Link>
                    </OverlayTrigger>
                  ) : (
                    withdrawal.number
                  )}
                </div>
                {isBackoffice && status !== WithdrawalStatus.WITHDRAWN && (
                  <div className="actions">
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                      type="button"
                      className="btn status-next"
                      // A faire remonter à morgan de changer le z-index
                      style={{ zIndex: 10 }}
                      disabled={socketLoading}
                      onClick={() => handleUpdateToNextStatus(withdrawal.id)}
                      title={`Passer cette commande au statut : ${getNextStatusText(status)}`}
                    >
                      <span className="icon" />
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </section>
    ) : null
  );
}

export default StatusSection;

StatusSection.propTypes = {
  status: PropTypes.string.isRequired,
  withdrawals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  })),
  isBackoffice: PropTypes.bool.isRequired,
  onOrderDetailClick: PropTypes.func,
};

StatusSection.defaultProps = {
  withdrawals: [],
  onOrderDetailClick: () => {},
};
