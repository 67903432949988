import React, { useCallback, useState } from 'react';

import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min';
import useWithdrawalCreated from '../hooks/useWithdrawalCreated';
import useWithdrawalStatusUpdated from '../hooks/useWithdrawalStatusUpdated';
import useWithdrawalNotifUpdated from '../hooks/useWithdrawalNotificationUpdated';
import useFetchBackofficeWithdrawals from '../hooks/useFetchBackofficeWithdrawals';
import Kanban from '../components/common/Kanban';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import OrderDetailModal from '../components/specific/backoffice/OrderDetailModal';

function BackofficePage() {
  const { withdrawals, loading, error } = useFetchBackofficeWithdrawals();
  // Hook pour écouter les événements de création de retrait via WebSocket
  useWithdrawalCreated(true);
  // Hook pour écouter les événements de mise à jour de statut de retrait via WebSocket
  useWithdrawalStatusUpdated();
  useWithdrawalNotifUpdated();

  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  // On stocke l'ID du retrait sélectionné pour l'utiliser dans le modal
  const [selectedWithdrawalId, setSelectedWithdrawalId] = useState(null);

  const handleShowOrderDetailModal = useCallback((withdrawalId) => {
    setSelectedWithdrawalId(withdrawalId);
    setShowOrderDetailModal(true);
  }, []);

  const handleCloseOrderDetailModal = useCallback(() => {
    setShowOrderDetailModal(false);
  }, []);

  return (
    <>
      <div id="pg" className="backoffice">
        <Navbar />
        <main id="pg-main">
          <section className="slice kanban">
            <div className="slice-wrapper">
              <div className="slice-content">
                <Kanban
                  withdrawals={withdrawals}
                  loading={loading}
                  error={error}
                  isBackoffice
                  onOrderDetailClick={handleShowOrderDetailModal}
                />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>

      <OrderDetailModal
        show={showOrderDetailModal}
        handleClose={handleCloseOrderDetailModal}
        withdrawalId={selectedWithdrawalId}
      />
    </>
  );
}

export default BackofficePage;
