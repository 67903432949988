import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  socketLoading: false,
};

/**
 * Slice pour la gestion des événements du socket
 */
const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    socketEventStart: (state) => {
      state.socketLoading = true;
    },
    socketEventEnd: (state) => {
      state.socketLoading = false;
    },
  },
});

export const { socketEventStart, socketEventEnd } = socketSlice.actions;

export default socketSlice.reducer;
