import React from 'react';
import Kanban from '../components/common/Kanban';
import useFetchWithdrawals from '../hooks/useFetchWithdrawals';
import useWithdrawalCreated from '../hooks/useWithdrawalCreated';
import useWithdrawalStatusUpdated from '../hooks/useWithdrawalStatusUpdated';

function CustomerQueuePage() {
  // eslint-disable-next-line global-require
  const { withdrawals, loading, error } = useFetchWithdrawals();

  useWithdrawalCreated();
  useWithdrawalStatusUpdated();

  return (
    <div id="pg" className="customerQueue">
      <main id="pg-main">
        <section className="slice kanban">
          <div className="slice-wrapper">
            <div className="slice-content">
              <Kanban
                withdrawals={withdrawals}
                loading={loading}
                error={error}
                isBackoffice={null}
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default CustomerQueuePage;
