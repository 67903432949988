// Les constantes liées aux statuts de retrait.
import i18next from '../i18n';

export const WithdrawalStatus = Object.freeze({
  PENDING: 'pending',
  ONGOING: 'ongoing',
  READY: 'ready',
  WITHDRAWN: 'withdrawn',
  CANCELLED: 'cancelled',
});

// TODO: utiliser les textes i18n
/**
 * Texte des statuts de retrait.
 *
 * @type {{ongoing: string, ready: string, pending: string, withdrawn: string, cancelled: string}}
 */
export const WithdrawalStatusText = {
  [WithdrawalStatus.PENDING]: i18next.t('withdrawalStatus.pending'),
  [WithdrawalStatus.ONGOING]: i18next.t('withdrawalStatus.ongoing'),
  [WithdrawalStatus.READY]: i18next.t('withdrawalStatus.ready'),
  [WithdrawalStatus.WITHDRAWN]: i18next.t('withdrawalStatus.withdrawn'),
  [WithdrawalStatus.CANCELLED]: i18next.t('withdrawalStatus.cancelled'),
};
