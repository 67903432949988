import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import endpoints from '../api/endpoints';

/**
 * Permet de gérer les appels à l'API pour l'authentification Azure SSO
 * */
export const azureSSOService = createApi({
  reducerPath: 'azureSSOService',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    // Ajout du token d'authentification et de l'email de l'utilisateur dans les headers
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState().auth;
      const { user } = getState().auth;
      if (token && user) {
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('X-User-Email', user.mail);
      }
      // Modifier la langue sans le code de région pour l'I18n
      headers.set('Accept-Language', 'fr');
      return headers;
    },
  }),
  // Définition des endpoints pour les appels à l'API
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials) => ({
        url: endpoints.AZURE_SSO_AUTH,
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: build.mutation({
      query: () => ({
        url: endpoints.AZURE_SSO_LOGOUT,
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = azureSSOService;
