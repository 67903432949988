import apiClient from '../api/apiClient';
import ENDPOINTS from '../api/endpoints';

// eslint-disable-next-line import/prefer-default-export
export const postOrderWithdrawal = async (data) => {
  const endpoint = ENDPOINTS.ORDER_WITHDRAWALS;
  return apiClient.post(endpoint, data);
};

export const updateNotifWithdrawal = async (withdrawalId, data) => {
  const endpoint = ENDPOINTS.ORDER_WITHDRAWALS;
  ENDPOINTS.backofficeWithdrawalStatus(withdrawalId);
  return apiClient.patch(endpoint, data);
};
