import { useState, useEffect, useCallback } from 'react';
// Le module est bien installé, mais il n'est pas reconnu par ESLint
// eslint-disable-next-line import/no-unresolved
import { formValidation } from '@form-validation/bundle/popular';
import { Bootstrap5 } from '@form-validation/plugin-bootstrap5';
import { SubmitButton } from '@form-validation/plugin-submit-button';
import { FieldStatus } from '@form-validation/plugin-field-status';
import { Sequence } from '@form-validation/plugin-sequence';
import { Trigger } from '@form-validation/plugin-trigger';

const useFormValidation = (kioskForm, orderNumber, orderNumberConfirmation) => {
  // État pour stocker la validité du formulaire, pour activer ou désactiver le submit
  const [isFormValid, setIsFormValid] = useState(false);
  const [formValidationInstance, setFormValidationInstance] = useState(null);

  const MAX_INPUT_LENGTH = 4;

  useEffect(() => {
    const fv = formValidation(kioskForm.current, {
      fields: {
        orderNumber: {
          validators: {
            digits: {
              message: 'Le numéro de commande doit être composé de chiffres',
            },
            notEmpty: {
              message: 'Veuillez saisir votre numéro de commande',
            },
            stringLength: {
              min: MAX_INPUT_LENGTH,
              max: MAX_INPUT_LENGTH,
              message: `Le numéro de commande doit être composé de ${MAX_INPUT_LENGTH} chiffres`,
            },
          },
        },
        orderNumberConfirmation: {
          validators: {
            digits: {
              message: 'Le numéro de commande doit être composé de chiffres',
            },
            notEmpty: {
              message: 'Veuillez saisir la confirmation de votre numéro de commande',
            },
            identical: {
              compare: () => orderNumber.current.value,
              message: 'Les numéros de commande ne correspondent pas',
            },
            stringLength: {
              min: MAX_INPUT_LENGTH,
              max: MAX_INPUT_LENGTH,
              message: `Le numéro de commande doit être composé de ${MAX_INPUT_LENGTH} chiffres`,
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger({
          // Délai pour déclencher la validation
          delay: 0.1,
          event: 'input change focus blur',
        }),
        bootstrap5: new Bootstrap5({
          // Définit la classe CSS pour les champs valides
          // Nécessaire pour le plugin Bootstrap5
          rowSelector: '.form-group',
        }),
        submitButton: new SubmitButton(),
        fieldStatus: new FieldStatus({
          onStatusChanged(areFieldsValid) {
            // On vérifie si les champs sont valides
            // et si le champ de confirmation de numéro de commande n'est pas vide
            setIsFormValid(areFieldsValid && orderNumberConfirmation.current.value.length > 0);
          },
        }),
        sequence: new Sequence({
          enabled: true,
        }),
      },
    });

    setFormValidationInstance(fv);
    return () => {
      fv.destroy();
    };
  }, [kioskForm, orderNumber, orderNumberConfirmation]);

  // Fonction pour Handles les KeyDown et empêcher la saisie de caractères autres que des chiffres
  const keyDown = (event) => {
    if (!/^\d$/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'Tab' && event.key !== 'Enter') {
      event.preventDefault();
    }
  };

  // Fonction pour limiter la longueur de l'input à 4 caractères
  const handleMaxLengthInput = (event) => {
    if (event.target.value.length >= MAX_INPUT_LENGTH) {
      event.target.value = event.target.value.slice(0, MAX_INPUT_LENGTH);
    }
  };

  // Fonction pour gérer le changement de numéro de commande
  const handleOrderNumberChange = useCallback((event) => {
    handleMaxLengthInput(event);
    // Permet de revérifier si les deux champs sont identiques
    // si l'utilisateur a rempli le champ de confirmation en premier
    if (
      formValidationInstance
      && orderNumberConfirmation.current.value.length === MAX_INPUT_LENGTH
    ) {
      formValidationInstance.revalidateField('orderNumberConfirmation');
    }
  }, [formValidationInstance, handleMaxLengthInput]);

  return {
    isFormValid, handleMaxLengthInput, keyDown, handleOrderNumberChange,
  };
};

export default useFormValidation;
