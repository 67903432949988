import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BACKOFFICE_LOGIN_ROUTE } from '../constants';

/**
 * Composant qui permet de protéger une route avec la connexion
 *
 * @param element la page à protéger
 * @returns {*|React.JSX.Element}
 * @constructor
 */
function ProtectedRoute({ element }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // Récupération de l'URL de la page actuelle
  const location = useLocation();

  // Si l'utilisateur est connecté, afficher la page, sinon rediriger vers la page de connexion
  return isAuthenticated
    ? element
    : <Navigate to={BACKOFFICE_LOGIN_ROUTE} state={{ from: location }} />;
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};
