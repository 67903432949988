import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeWithdrawalStatus } from '../store/actions/withdrawalDetailActions';
import { socketEventStart } from '../store/slices/socketSlice';
import { WithdrawalStatus } from '../constants';
import useFetchWithdrawalDetail from './useFetchWithdrawalDetail';

const useOrderDetail = (show, withdrawalId, handleClose) => {
  const dispatch = useDispatch();
  const { socketLoading } = useSelector((state) => state.socket);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);
  const [localChangeStatusError, setLocalChangeStatusError] = useState(null);

  const { withdrawal, loading, changeStatusError } = useFetchWithdrawalDetail(withdrawalId, show);

  // Mise à jour du statut sélectionné si le statut du retrait change
  useEffect(() => {
    if (withdrawal) {
      setSelectedStatus(withdrawal.status);
    }
  }, [withdrawal]);

  // Met à jour l'erreur de changement de statut dans le composant
  useEffect(() => {
    setLocalChangeStatusError(changeStatusError);
  }, [changeStatusError]);

  // Ferme le modal si le changement de statut reçu du socket est terminé
  useEffect(() => {
    if (!socketLoading || !show) {
      handleClose();
    }
  }, [socketLoading, handleClose]);

  // Fonction pour mettre à jour le statut sélectionné
  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  // Vérifie si le statut sélectionné nécessite une confirmation
  const checkIfStatusNeedsConfirmation = (status) => status === WithdrawalStatus.CANCELLED;

  // Fonction pour changer le statut du retrait
  const handleUpdateStatus = async () => {
    // Ouverture du modal de confirmation si le statut nécessite une confirmation
    if (checkIfStatusNeedsConfirmation(selectedStatus)) {
      handleClose();
      setShowConfirmActionModal(true);
      return;
    }
    const result = await dispatch(changeWithdrawalStatus({ withdrawalId, status: selectedStatus }));

    if (changeWithdrawalStatus.fulfilled.match(result)) {
      dispatch(socketEventStart());
    }
  };

  const handleCloseConfirmActionModal = () => {
    setShowConfirmActionModal(false);
  };

  return {
    withdrawal,
    loading,
    socketLoading,
    selectedStatus,
    showConfirmActionModal,
    localChangeStatusError,
    handleStatusChange,
    handleUpdateStatus,
    handleCloseConfirmActionModal,
    setLocalChangeStatusError,
  };
};

export default useOrderDetail;
