import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import echo from '../echo';
import { addWithdrawal } from '../store/actions/withdrawalActions';

/**
 * Hook pour écouter la création d'un retrait depuis un événement
 *
 * @returns {null}
 */
const useWithdrawalCreated = (isBackoffice = false) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const channel = echo.channel('italie2_database_order-withdrawals');

    // On écoute l'événement OrderWithdrawalCreatedEvent
    channel.listen('OrderWithdrawalCreatedEvent', (withdrawal) => {
      dispatch(addWithdrawal({ withdrawal, isBackoffice }));
    });

    return () => {
      // Lors du démontage du composant, on arrête d'écouter l'événement
      channel.stopListening('OrderWithdrawalCreatedEvent');
      echo.leaveChannel('italie2_database_order-withdrawals');
    };
  }, [dispatch, isBackoffice]);

  return null;
};

export default useWithdrawalCreated;
