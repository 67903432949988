import React, { useEffect, useMemo, useRef } from 'react';
import jQuery from 'jquery';

// eslint-disable-next-line react/prop-types
function SelectStatus({ withdrawalStatus, onStatusChange }) {
  useEffect(() => {
    // Initialisation des selectpicker de bootstrap-select
    jQuery('.selectpicker').selectpicker();
  }, []);

  const selectRef = useRef(null);

  // Utilisation de useMemo pour éviter de recréer les options à chaque rendu de SelectStatus
  const options = useMemo(() => (
    <>
      <option
        disabled
        value="pending"
        data-content="<span class='status status-pending'><span class='icon'></span><span class='text'>Non commencé</span></span>"
      >
        Non commencé
      </option>
      <option
        disabled={withdrawalStatus === 'ready' || withdrawalStatus === 'withdrawn' || withdrawalStatus === 'cancelled'}
        value="ongoing"
        data-content="<span class='status status-ongoing'><span class='icon'></span><span class='text'>En cours de préparation</span></span>"
      >
        En cours de préparation
      </option>
      <option
        disabled={withdrawalStatus === 'withdrawn' || withdrawalStatus === 'cancelled'}
        value="ready"
        data-content="<span class='status status-ready'><span class='icon'></span><span class='text'>Prête</span></span>"
      >
        Prête
      </option>
      <option
        disabled={withdrawalStatus === 'cancelled' || withdrawalStatus === 'withdrawn'}
        value="withdrawn"
        data-content="<span class='status status-withdrawn'><span class='icon'></span><span class='text'>Remis client</span></span>"
      >
        Remis client
      </option>
      <option
        disabled={withdrawalStatus === 'withdrawn'}
        value="cancelled"
        data-content="<span class='status status-cancelled'><span class='icon'></span><span class='text'>Annulé</span></span>"
      >
        Annulé
      </option>
    </>
  ), [withdrawalStatus]);

  return (
    <select
      ref={selectRef}
      className="selectpicker statusSelector"
      id="orderDetail-statusSelector"
      defaultValue={withdrawalStatus}
      disabled={withdrawalStatus === 'cancelled' || withdrawalStatus === 'withdrawn'}
      onChange={(e) => onStatusChange(e.target.value)}
    >
      {options}
    </select>
  );
}

export default SelectStatus;
