import Echo from 'laravel-echo';
import io from 'socket.io-client';

if (!process.env.REACT_APP_API_BASE_URL) {
  throw new Error('Veuillez définir la variable d\'environnement REACT_APP_API_BASE_URL dans le fichier .env');
}

window.io = io;
// Initialisation de l'instance de Echo
const echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.REACT_APP_API_BASE_URL + (process.env.REACT_APP_SOCKET_PORT ? `:${process.env.REACT_APP_SOCKET_PORT}` : ''),
});

export default echo;
