import React from 'react';
import Navbar from '../components/common/Navbar';
import KioskForm from '../components/specific/kiosk/KioskForm';
import CarouselWorkflow from '../components/specific/kiosk/CarouselWorkflow';

function KioskPage() {
  return (
    <div id="pg" className="kiosk">

      <Navbar />

      <main id="pg-main">

        <section className="slice">
          <div className="slice-wrapper">
            <div className="slice-content">

              <section className="slice workflow withdrawOrder">
                <div className="slice-wrapper">
                  <header className="slice-header">
                    <h1>Retirer mon achat au Parking</h1>
                  </header>
                  <div className="slice-content">
                    <CarouselWorkflow />
                    <div className="workflow-content">
                      <section className="intro">
                        <h2>
                          Vous êtes stationnés au parking et vous avez une commande à retirer ?
                        </h2>
                      </section>
                      <KioskForm />
                    </div>
                  </div>
                </div>

              </section>

            </div>
          </div>
        </section>

      </main>

      <footer id="pg-ftr">
        <div className="pg-ftr-wrapper">
          <section className="slice bottom">
            <div className="wrapper">
              <div className="slice-content">
                <p className="copyright">&copy; Inter IKEA Systems B.V 1999-2024</p>
              </div>
            </div>
          </section>
        </div>
      </footer>

    </div>
  );
}

export default KioskPage;
