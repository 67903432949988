/* eslint-disable import/prefer-default-export */

/**
 * Ajoute un élément à un tableau trié tout en maintenant l'ordre de tri.
 *
 * @param {Array} array - Le tableau trié.
 * @param {Object} item - L'élément qui sera ajouté au tableau.
 * @param {string} key - La clé de l'objet utilisée pour le tri. Par exemple, 'number'.
 * @returns {Array} Le tableau trié avec l'élément ajouté.
 */
export const insertSorted = (array, item, key) => {
  const newArray = [...array, item];
  // Trier le tableau en fonction de la clé spécifiée
  return newArray.sort((a, b) => (a[key] > b[key] ? 1 : -1));
};
