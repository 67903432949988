import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateWithdrawalNotification } from '../../../store/actions/withdrawalActions';
import useFormValidation from '../../../hooks/useFormValidation';
import { confirmWithdrawalNotificationRoute } from '../../../constants';

/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/**
 * Formulaire pour saisir le numéro de commande et sa confirmation
 *
 * @returns {Element}
 */
function KioskNotificationForm() {
  const orderNumber = useRef(null);
  const orderNumberConfirmation = useRef(null);
  const kioskForm = useRef(null);

  // Il va falloir que j utilise les sockets pour envoyer les notifications
  // const { socketLoading } = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  const { orderNotification, error, loading } = useSelector((state) => state.orderNotification);

  const {
    isFormValid,
    handleMaxLengthInput,
    keyDown,
    handleOrderNumberChange,
  } = useFormValidation(kioskForm, orderNumber, orderNumberConfirmation);

  const navigate = useNavigate();

  // On stocke l'ordre de retrait initial pour comparer les changements
  // const initialOrderWithdrawal = useRef(orderWithdrawal);

  // Fonction pour créer la notification de retrait
  const handleNotifWithDrawal = useCallback((event) => {
    event.preventDefault();
    const orderNumberValue = orderNumber.current.value;
    dispatch(updateWithdrawalNotification({ orderNumberValue }));
  }, [dispatch]);

  useEffect(() => {
    // Si l'ordre de retrait a changé et qu'il n'y a pas d'erreur,
    // on redirige vers la page de confirmation
    if (orderNotification?.number) {
      navigate(confirmWithdrawalNotificationRoute(orderNotification.number));
    }
  }, [orderNotification, error, navigate]);

  return (
    <section className="form">
      <form id="withdrawOrderForm" ref={kioskForm}>
        <div className="form-group">
          <label className="form-label" htmlFor="orderNumber">
            Saisissez les quatre derniers chiffres de votre numéro de
            commande :
          </label>
          <input
            type="number"
            min="0000"
            max="9999"
            step="1"
            className="form-control"
            id="orderNumber"
            name="orderNumber"
            tabIndex="1"
            autoFocus
            placeholder="0000"
            ref={orderNumber}
            onChange={handleOrderNumberChange}
            onKeyDown={keyDown}
          />
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="orderNumberConfirmation">
            Veuillez confirmer les quatre derniers
            chiffres de votre numéro de commande :
          </label>
          <input
            type="number"
            min="0000"
            max="9999"
            step="1"
            className="form-control"
            id="orderNumberConfirmation"
            name="orderNumberConfirmation"
            tabIndex="2"
            placeholder="0000"
            ref={orderNumberConfirmation}
            onChange={handleMaxLengthInput}
            onKeyDown={keyDown}
          />
        </div>
        {error && (
          <div className="form-error">
            {error}
          </div>
        )}

        <div className="form-submit">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-clientReady"
            tabIndex="3"
            onClick={handleNotifWithDrawal}
            disabled={!isFormValid || loading}
          >
            <span className="icon" />
            <span className="text">
              Annoncer mon arrivée
            </span>
          </button>

        </div>
      </form>
    </section>
  );
}

export default KioskNotificationForm;
