import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StatusSection from './StatusSection';
import LoaderOverlay from './LoaderOverlay';
import { WithdrawalStatus } from '../../constants';
import { errorDefaultProps, errorProps } from '../../propTypes/errorProps';
import { withdrawalsDefaultProps, withdrawalsProps } from '../../propTypes/withdrawalsProps';

function Kanban({
  withdrawals, loading, error, isBackoffice, onOrderDetailClick,
}) {
  const { t } = useTranslation();
  const renderLoader = () => <LoaderOverlay />;

  const renderError = () => (
    <p>
      Erreur:
      {error.message || error}
    </p>
  );

  const renderStatusSection = () => (
    <>
      <StatusSection
        status={WithdrawalStatus.PENDING}
        withdrawals={withdrawals.pending}
        isBackoffice={isBackoffice}
        onOrderDetailClick={onOrderDetailClick}
      />
      <StatusSection
        status={WithdrawalStatus.ONGOING}
        withdrawals={withdrawals.ongoing}
        isBackoffice={isBackoffice}
        onOrderDetailClick={onOrderDetailClick}
      />
      <StatusSection
        status={WithdrawalStatus.READY}
        withdrawals={withdrawals.ready}
        isBackoffice={isBackoffice}
        onOrderDetailClick={onOrderDetailClick}
      />
      <StatusSection
        status={WithdrawalStatus.WITHDRAWN}
        withdrawals={withdrawals.withdrawn}
        isBackoffice={isBackoffice}
        onOrderDetailClick={onOrderDetailClick}
      />
    </>
  );

  return (
    <div className="kanban">
      {withdrawals.pending && isBackoffice && (
      <header className="status-pending">
        <h2>
          <span className="icon" />
          <span className="text">{t('withdrawalStatus.pending')}</span>
        </h2>
        {isBackoffice && (
          <p className="help">Scanne le QR code sur CPS Préparation pour effectuer la préparation.</p>
        )}
      </header>
      )}
      {withdrawals.ongoing && (
      <header className="status-ongoing">
        <h2>
          <span className="icon" />
          <span className="text">{t('withdrawalStatus.ongoing')}</span>
        </h2>
      </header>
      )}
      {withdrawals.ready && (
      <header className="status-ready">
        <h2>
          <span className="icon" />
          <span className="text">{t('withdrawalStatus.ready')}</span>
        </h2>
        {isBackoffice && (
          <p className="help">
            Scanne le QR code sur CPS pour contrôler et confirmer le contrôle de
            commande.
          </p>
        )}
      </header>
      )}
      {withdrawals.withdrawn && (
        <header className="status-withdrawn">
          <h2>
            <span className="icon" />
            <span className="text">{t('withdrawalStatus.withdrawn')}</span>
          </h2>
          {isBackoffice && (
            <p className="help">Scanne le QR code sur CPS Contrôler pour confirmer la remise.</p>
          )}
        </header>
      )}
      {/* Affiche le section de status si les retraits sont chargés et qu'il n'y a pas d'erreur */}
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? renderLoader() : error ? renderError() : renderStatusSection()}
    </div>
  );
}

export default Kanban;

Kanban.propTypes = {
  withdrawals: withdrawalsProps.withdrawals,
  loading: PropTypes.bool.isRequired,
  error: errorProps.error,
  isBackoffice: PropTypes.bool,
  onOrderDetailClick: PropTypes.func,
};

Kanban.defaultProps = {
  withdrawals: withdrawalsDefaultProps.withdrawals,
  error: errorDefaultProps.error,
  isBackoffice: false,
  onOrderDetailClick: () => {},
};
