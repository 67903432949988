import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBackofficeWithdrawals } from '../store/actions/withdrawalActions';

/**
 * Hook pour récupérer les retraits en backoffice
 *
 * @returns {{loading: *, error: *, withdrawals: *}}
 */
const useFetchBackofficeWithdrawals = () => {
  const dispatch = useDispatch();
  const { withdrawals, loading, error } = useSelector((state) => state.withdrawals);

  useEffect(() => {
    dispatch(fetchBackofficeWithdrawals());
  }, [dispatch]);

  // On retourne les retraits, l'état de chargement et les erreurs
  return { withdrawals, loading, error };
};

export default useFetchBackofficeWithdrawals;
