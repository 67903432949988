import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBackofficeWithdrawalDetailById } from '../../services/backofficeWithdrawalService';
import { patchWithdrawalStatus } from '../../services/withdrawalService';

export const fetchBackofficeWithdrawalDetailById = createAsyncThunk(
  'backofficeWithdrawals/fetchBackofficeWithdrawalDetailById',
  async ({ withdrawalId }, { rejectWithValue }) => {
    try {
      const response = await getBackofficeWithdrawalDetailById(withdrawalId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);

/**
 * Création d'une action asynchrone pour changer le statut d'un retrait
 * createAsyncThunk est une fonction qui prend deux arguments :
 * un nom pour l'action et une fonction qui retourne une promesse
 */
export const changeWithdrawalStatus = createAsyncThunk(
  'backofficeWithdrawals/changeWithdrawalStatus',
  async ({ withdrawalId, status }, { rejectWithValue }) => {
    try {
      const response = await patchWithdrawalStatus(withdrawalId, { status });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);
