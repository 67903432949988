import { createSlice } from '@reduxjs/toolkit';
import { createOrderWithdrawal } from '../actions/orderWithdrawalActions';

/* eslint-disable no-param-reassign */

const initialState = {
  orderWithdrawal: {},
  loading: false,
  error: null,
};

const orderWithdrawalSlice = createSlice({
  name: 'orderWithdrawal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrderWithdrawal.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createOrderWithdrawal.fulfilled, (state, action) => {
        state.loading = false;
        state.orderWithdrawal = action.payload;
      })
      .addCase(createOrderWithdrawal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default orderWithdrawalSlice.reducer;
