import apiClient from '../api/apiClient';
import ENDPOINTS from '../api/endpoints';

export const getBackofficeWithdrawals = async () => {
  const endpoint = ENDPOINTS.BACKOFFICE_WITHDRAWAL;
  return apiClient.get(endpoint);
};

export const getBackofficeWithdrawalDetailById = async (withdrawalId) => {
  const endpoint = ENDPOINTS.backofficeWithdrawalDetail(withdrawalId);
  return apiClient.get(endpoint);
};
