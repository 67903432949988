import React from 'react';
import Navbar from '../components/common/Navbar';
import ImgLanding2 from '../assets/images/landing2.avif';
import KioskNotificationForm from '../components/specific/kiosk/KioskNotificationForm';

function KioskNotificationPage() {
  return (
    <div id="pg" className="kiosk">

      <Navbar />

      <main id="pg-main">

        <section className="slice">
          <div className="slice-wrapper">
            <div className="slice-content">
              <section className="slice workflow clientReady">
                <div className="slice-wrapper">
                  <header className="slice-header">
                    <h1>Retirer mon achat au Parking</h1>
                  </header>
                  <div className="slice-content">
                    <div className="workflow-illustration">
                      <img src={ImgLanding2} alt="Retrait Parking" title="Retrait Parking" />
                    </div>
                    <div className="workflow-content">
                      <section className="intro">
                        <h2>Vous êtes prêts à retirer votre commande au parking ?</h2>
                      </section>
                      <section className="form">
                        <KioskNotificationForm />
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

      </main>

      <footer id="pg-ftr">
        <div className="pg-ftr-wrapper">
          <section className="slice bottom">
            <div className="wrapper">
              <div className="slice-content">
                <p className="copyright">&copy; Inter IKEA Systems B.V 1999-2024</p>
              </div>
            </div>
          </section>
        </div>
      </footer>

    </div>
  );
}

export default KioskNotificationPage;
