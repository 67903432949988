import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBackofficeWithdrawalDetailById } from '../store/actions/withdrawalDetailActions';

/**
 * Hook pour récupérer le détail d'un retrait en backoffice
 *
 * @param withdrawalId - ID du retrait à récupérer
 * @param show - vérifier si le retrait doit être affiché
 * @returns {{withdrawal, loading, error}} - Le retrait, l'état de chargement et les erreurs
 */
const useFetchWithdrawalDetail = (withdrawalId, show) => {
  const dispatch = useDispatch();
  const { withdrawal, loading, changeStatusError } = useSelector((state) => state.withdrawalDetail);

  useEffect(() => {
    if (show && withdrawalId) {
      dispatch(fetchBackofficeWithdrawalDetailById({ withdrawalId }));
    }
  }, [dispatch, withdrawalId, show]);

  return { withdrawal, loading, changeStatusError };
};

export default useFetchWithdrawalDetail;
