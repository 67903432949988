const en = {
  // Pour l'instant les messages sont en français
  // Mais ils devraient être traduits en anglais lors de l'internationalisation
  translation: {
    withdrawalStatus: {
      pending: 'Non commencé',
      ongoing: 'En cours de préparation',
      ready: 'Prête',
      withdrawn: 'Remis Client',
      cancelled: 'Annulé',
    },
    errorMessages: {
      networkError: 'Une erreur est survenue lors de la communication avec le serveur',
    },
  },
};

export default en;
