import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getWithdrawals,
  patchWithdrawalUpdate,
} from '../../services/withdrawalService';
import { getBackofficeWithdrawals } from '../../services/backofficeWithdrawalService';

/**
 * Création d'une action asynchrone pour récupérer les retraits
 * createAsyncThunk est une fonction qui prend deux arguments :
 * un nom pour l'action et une fonction qui retourne une promesse
 */
export const fetchWithdrawals = createAsyncThunk(
  'withdrawals/fetchWithdrawals',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getWithdrawals();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);

export const fetchBackofficeWithdrawals = createAsyncThunk(
  'withdrawals/fetchBackofficeWithdrawals',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getBackofficeWithdrawals();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);
/**
 * Création d'une action asynchrone pour ajouter un retrait
 * createAsyncThunk est une fonction qui prend deux arguments :
 * un nom pour l'action et une fonction qui retourne une promesse
 */
export const addWithdrawal = createAsyncThunk(
  'withdrawals/addWithdrawal',
  async ({ withdrawal, isBackoffice }, { rejectWithValue }) => {
    try {
      return { withdrawal, isBackoffice };
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);

/**
 * Création d'une action asynchrone pour mettre à jour le statut d'un retrait
 */
export const updateWithdrawalStatus = createAsyncThunk(
  'withdrawals/updateWithdrawalStatus',
  async ({ updatedWithdrawal }, { rejectWithValue }) => {
    try {
      return updatedWithdrawal;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);

// Creation d'une action asynchrone pour mettre à jour la notif d'un retrait
export const updateWithdrawalNotification = createAsyncThunk(
  'withdrawals/updateWithdrawalNotification',
  async ({ orderNumberValue }, { rejectWithValue }) => {
    try {
      const response = await patchWithdrawalUpdate(orderNumberValue);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);
