import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { changeWithdrawalStatus } from '../../../store/actions/withdrawalDetailActions';
import Loader from '../../common/Loader';
import { socketEventStart } from '../../../store/slices/socketSlice';
import { WithdrawalStatusText } from '../../../constants';

function ConfirmActionModal(
  {
    show,
    handleClose,
    withdrawalId,
    withdrawalNumber,
    status,
    error,
  },
) {
  const statusText = WithdrawalStatusText[status];
  // State pour stocker le statut sélectionné
  const dispatch = useDispatch();
  const { socketLoading } = useSelector((state) => state.socket);
  const handleUpdateStatus = async () => {
    const result = await dispatch(changeWithdrawalStatus({ withdrawalId, status }));

    // Après avoir changé le statut, on ferme le modal
    if (changeWithdrawalStatus.fulfilled.match(result)) {
      dispatch(socketEventStart());
    }
  };

  useEffect(() => {
    if (!socketLoading) {
      handleClose();
    }
  }, [socketLoading]);

  if (!show) {
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      id="orderDetail"
      tabIndex="-1"
      aria-labelledby="confirmActionLabel"
      aria-hidden="true"
    >
      <Modal.Header closeButton>
        <h4 className="modal-title" id="confirmActionLabel">Confirmer l&apos;action</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="order">
          <header>
            <span>Commande numéro</span>
            <h2>{withdrawalNumber}</h2>
          </header>
          <div className="status-change">
            <div className="confirmation">
              <p>
                Vous souhaitez passer la commande
                <b>
                  {' '}
                  {withdrawalNumber}
                  {' '}
                </b>
                au statut&nbsp;:
              </p>
              <div className={`status status-${status}`}>
                <span className="icon" />
                <span className="text">{statusText}</span>
              </div>
            </div>
          </div>
        </div>
        {error && (
        <div role="alert" aria-live="assertive" className="mt-4">
          <p className="text-danger">{error}</p>
        </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Annuler</Button>
        <Button variant="primary" onClick={handleUpdateStatus} disabled={socketLoading}>
          {socketLoading ? <Loader /> : 'Valider'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmActionModal;

ConfirmActionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  withdrawalId: PropTypes.string,
  withdrawalNumber: PropTypes.string,
  status: PropTypes.string,
  error: PropTypes.string,
};

ConfirmActionModal.defaultProps = {
  withdrawalId: '',
  withdrawalNumber: '',
  status: '',
  error: '',
};
