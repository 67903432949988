/*
  ------------------------------------------------------------------------------
  |                                                                            |
  | Il faut remplacer la logique de appel API de axios par celle de RTK Query  |
  | Pour cela, il faut créer un service pour chaque appel API                  |
  | et remplacer les appels API par les hooks générés par RTK Query            |
  |                                                                            |
  | Nous utilisons actuellement axios et RTK Query pour gérer les appels API   |
  |                                                                            |
  ------------------------------------------------------------------------------
*/

import axios from 'axios';
import i18next from '../i18n';

if (!process.env.REACT_APP_API_BASE_URL) {
  throw new Error('La variable d\'environnement REACT_APP_API_BASE_URL doit être définie dans le fichier .env');
}

// Définition de l'instance axios pour les appels à l'API
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // Modifier la langue sans le code de région pour l'I18n
    'Accept-Language': 'fr',
  },
});

// Interception des requêtes pour ajouter le token d'authentification et l'email de l'utilisateur
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));
    if (token && user) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['X-User-Email'] = user.mail;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Interception des requêtes et des réponses pour gérer les erreurs
apiClient.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

// Interception des réponses pour gérer les réponses et les erreurs
apiClient.interceptors.response.use(
  // retourner les données de la réponse (response.data) si la requête a réussi
  (response) => response.data,
  (error) => {
    if (error.response) {
      // Si la requête a échoué avec une erreur 403 (non autorisé), déconnecter l'utilisateur
      if (error.response.status === 403) {
        // Déconnexion de l'utilisateur manuellement
        // TODO: Créer une action pour déconnecter l'utilisateur et le supprimer du store
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.reload();
      }

      return Promise.reject(error.response.data);
    }
    // Si la requête n'a pas abouti, retourner une erreur générique
    return Promise.reject(new Error(
      i18next.t('errorMessages.networkError'),
    ));
  },
);

export default apiClient;
