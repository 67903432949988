/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import PropTypes from 'prop-types';
import SelectStatus from './SelectStatus';
import Loader from '../../common/Loader';
import ConfirmActionModal from './ConfirmActionModal';
import useOrderDetail from '../../../hooks/useOrderDetail';
import LoaderOverlay from '../../common/LoaderOverlay';

/**
 * Modal pour afficher le détail d'un retrait et permettre de changer son statut
 *
 * @param show - Afficher ou non le modal
 * @param handleClose - Fonction pour fermer le modal
 * @param withdrawalId - ID du retrait à afficher
 * @returns {Element} - Le composant OrderDetailModal
 */
function OrderDetailModal({
  show, handleClose, withdrawalId,
}) {
  const {
    withdrawal,
    loading,
    socketLoading,
    selectedStatus,
    showConfirmActionModal,
    localChangeStatusError,
    handleStatusChange,
    handleUpdateStatus,
    handleCloseConfirmActionModal,
    setLocalChangeStatusError,
  } = useOrderDetail(show, withdrawalId, handleClose);

  const handleModalClose = () => {
    handleClose();
    setLocalChangeStatusError(null);
  };

  if (loading) {
    return <LoaderOverlay />;
  }

  if (!withdrawal) {
    return null;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleModalClose}
        id="orderDetail"
        tabIndex="-1"
        aria-labelledby="orderDetailLabel"
        aria-hidden="true"
      >
        <Modal.Header closeButton>
          <h4 id="orderDetailLabel">Détail Commande</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="order">
            <header>
              <span>Commande numéro</span>
              <h2>{withdrawal.number}</h2>
              {/* Montre si le client est ready */}
              <ul className="code flags">
                {withdrawal.ready === 1 && (
                  <li className="flag clientReady">
                    <span className="icon" style={{ backgroundImage: 'url(icons/icon-person-white.svg)' }} />
                    <span className="text">Client arrivé</span>
                  </li>
                )}
              </ul>
            </header>
            <div className="qrcode-container">
              <QRCodeCanvas
                value={withdrawal.number}
                className="qrcode"
                title={withdrawal.number}
                size={240}
              />
            </div>
            <div className="status-change">
              <form>
                <div className="form-group">
                  <label htmlFor="orderDetail-statusSelector" className="form-label">Statut de la commande</label>
                  <SelectStatus
                    withdrawalStatus={withdrawal.status}
                    onStatusChange={handleStatusChange}
                  />
                  {localChangeStatusError && (
                  <div role="alert" aria-live="assertive" className="mt-4">
                    <p className="text-danger">{localChangeStatusError}</p>
                  </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary btn-cancel"
            title="Annuler"
            onClick={handleModalClose}
          >
            <span className="icon" />
            <span className="text">
              Annuler
            </span>
          </button>
          <button
            type="button"
            className="btn btn-primary btn-ok w100"
            id="orderDetail-saveBtn"
            title="Enregistrer"
            onClick={handleUpdateStatus}
            disabled={selectedStatus === withdrawal.status || socketLoading}
          >
            {!socketLoading && <span className="icon" />}
            <span className="text">
              {socketLoading ? <Loader /> : 'Enregistrer'}
            </span>
          </button>
        </Modal.Footer>
      </Modal>
      <ConfirmActionModal
        show={showConfirmActionModal}
        handleClose={handleCloseConfirmActionModal}
        withdrawalId={withdrawalId}
        withdrawalNumber={withdrawal.number}
        status={selectedStatus}
        error={localChangeStatusError}
      />
    </>
  );
}

export default OrderDetailModal;

OrderDetailModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  withdrawalId: PropTypes.string,
};

OrderDetailModal.defaultProps = {
  withdrawalId: '',
};
