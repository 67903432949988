import {
  WithdrawalStatus, WithdrawalStatusText,
} from '../constants';

/**
 * Ordre des statuts de retrait. Les statuts sont triés dans l'ordre de progression.
 *
 * @type {(string)[]}
 */
export const withdrawalStatusOrder = [
  WithdrawalStatus.PENDING,
  WithdrawalStatus.ONGOING,
  WithdrawalStatus.READY,
  WithdrawalStatus.WITHDRAWN,
];

/**
 * Récupère le statut suivant.
 * @param {string} currentStatus - Le statut actuel.
 * @returns {string|null} Le statut suivant ou null s'il n'y en a pas.
 */
export const getNextStatus = (currentStatus) => {
  const currentIndex = withdrawalStatusOrder.indexOf(currentStatus);
  return currentIndex >= 0 && currentIndex < withdrawalStatusOrder.length - 1
    ? withdrawalStatusOrder[currentIndex + 1]
    : null;
};

/**
 * Récupère le texte du statut suivant.
 * @param currentStatus - Le statut actuel.
 * @returns {*|string} Le texte du statut suivant ou une chaîne vide s'il n'y en a pas.
 */
export const getNextStatusText = (currentStatus) => {
  const nextStatus = getNextStatus(currentStatus);
  return nextStatus ? WithdrawalStatusText[nextStatus] : '';
};
