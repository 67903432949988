import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import echo from '../echo';
import { updateWithdrawalStatus } from '../store/actions/withdrawalActions';
import { socketEventEnd } from '../store/slices/socketSlice';

/**
 * Hook pour écouter la mise à jour de la Notification d'un retrait depuis un événement
 * @returns {null}
 */
const useWithdrawalNotifUpdated = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const channel = echo.channel('italie2_database_update-withdrawal-ready');

    channel.listen('WithdrawalNotifUpdatedEvent', (updatedWithdrawal) => {
      dispatch(updateWithdrawalStatus({ updatedWithdrawal }));
      dispatch(socketEventEnd());
    });

    return () => {
      dispatch(socketEventEnd());
      channel.stopListening('WithdrawalNotifUpdatedEvent');
      echo.leaveChannel('italie2_database_update-withdrawal-ready');
    };
  }, [dispatch]);

  return null;
};

export default useWithdrawalNotifUpdated;
