import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createOrderWithdrawal } from '../../../store/actions/orderWithdrawalActions';
import useFormValidation from '../../../hooks/useFormValidation';
import { confirmWithdrawalRoute } from '../../../constants';

/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/**
 * Formulaire pour saisir le numéro de commande et sa confirmation
 *
 * @returns {Element}
 */
function KioskForm() {
  const orderNumber = useRef(null);
  const orderNumberConfirmation = useRef(null);
  const kioskForm = useRef(null);

  const dispatch = useDispatch();
  const { orderWithdrawal, error, loading } = useSelector((state) => state.orderWithdrawal);

  const {
    isFormValid,
    handleMaxLengthInput,
    keyDown,
    handleOrderNumberChange,
  } = useFormValidation(kioskForm, orderNumber, orderNumberConfirmation);

  const navigate = useNavigate();

  // On stocke l'ordre de retrait initial pour comparer les changements
  const initialOrderWithdrawal = useRef(orderWithdrawal);

  // Fonction pour mettre le focus sur un élément
  const handleFocusOnClick = (ref) => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  // Fonction pour créer un ordre de retrait
  const handleCreateOrderWithdrawal = useCallback((event) => {
    event.preventDefault();
    const orderNumberValue = orderNumber.current.value;
    dispatch(createOrderWithdrawal({ data: { short_number: orderNumberValue } }));
  }, [dispatch]);

  useEffect(() => {
    // Si l'ordre de retrait a changé et qu'il n'y a pas d'erreur,
    // on redirige vers la page de confirmation
    if (orderWithdrawal.number && orderWithdrawal !== initialOrderWithdrawal.current && !error) {
      navigate(confirmWithdrawalRoute(orderWithdrawal.number));
    }
  }, [orderWithdrawal, error, navigate]);

  return (
    <section className="form">
      <form id="withdrawOrderForm" ref={kioskForm}>
        <div className="form-group">
          <label className="form-label" htmlFor="orderNumber">
            Saisissez les quatre derniers chiffres de votre numéro de
            commande :
          </label>
          <input
            type="number"
            min="0000"
            max="9999"
            step="1"
            className="form-control"
            id="orderNumber"
            name="orderNumber"
            tabIndex="1"
            placeholder="0000"
            ref={orderNumber}
            onClick={() => handleFocusOnClick(orderNumber)}
            onChange={handleOrderNumberChange}
            onKeyDown={keyDown}
          />
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="orderNumberConfirmation">
            Veuillez confirmer les quatre derniers
            chiffres de votre numéro de commande :
          </label>
          <input
            type="number"
            min="0000"
            max="9999"
            step="1"
            className="form-control"
            id="orderNumberConfirmation"
            name="orderNumberConfirmation"
            tabIndex="2"
            placeholder="0000"
            ref={orderNumberConfirmation}
            onClick={() => handleFocusOnClick(orderNumberConfirmation)}
            onChange={handleMaxLengthInput}
            onKeyDown={keyDown}
          />
        </div>
        {error && (
          <div className="form-error">
            {error}
          </div>
        )}

        <div className="form-submit">
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            tabIndex="3"
            onClick={handleCreateOrderWithdrawal}
            disabled={!isFormValid || loading}
          >
            <span className="icon" />
            <span className="text">
              Retirer mes achats au parking
            </span>
          </button>

        </div>
      </form>
    </section>
  );
}

export default KioskForm;
