import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/common/Navbar';
import landing2 from '../assets/images/illustrations/landing2.avif';
import Footer from '../components/common/Footer';
import { useLoginMutation } from '../services/azureSSOService';
import { setCredentials } from '../store/slices/authSlice';
import { BACKOFFICE_ROUTE } from '../constants';

/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-autofocus */
function BackofficeLoginPage() {
  const email = useRef(null);
  const password = useRef(null);
  const [login, { isLoading, error }] = useLoginMutation();
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  // D'où vient l'utilisateur s'il a été redirigé vers la page de connexion
  const from = location.state?.from?.pathname || BACKOFFICE_ROUTE;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userData = await login({
        email: email.current.value,
        password: password.current.value,
      }).unwrap();
      dispatch(setCredentials(userData));
      // Si l'utilisateur a été redirigé, le renvoyer vers la page où il voulait aller
      // Sinon, le rediriger vers la page d'accueil du backoffice
      navigate(from);
    } catch (err) {
      // Afficher le message d'erreur de l'API s'il existe, sinon afficher un message générique
      setErrorMessage(err.data?.message || t('errorMessages.networkError'));
    }
  };

  return (
    <div id="pg" className="backoffice">
      <Navbar />
      <main id="pg-main">

        <section className="slice signIn">
          <div className="slice-wrapper">
            <header className="slice-header">
              <h1>Se connecter</h1>
            </header>
            <div className="slice-content">

              <div className="signIn-illustration">
                <img
                  src={landing2}
                  alt="Italie Deux - Retrait"
                  title="Italie Deux - Retrait"
                />
              </div>
              <div className="signIn-form">
                <div className="form">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="signInLogin">Identifiant</label>
                      <input
                        ref={email}
                        type="email"
                        className="form-control"
                        id="signInLogin"
                        name="signInLogin"
                        tabIndex="1"
                        autoFocus
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="signInPassword">Mot de passe</label>
                      <input
                        type="password"
                        ref={password}
                        className="form-control"
                        id="signInPassword"
                        name="signInPassword"
                        tabIndex="2"
                      />
                    </div>
                    {error && (
                      <div className="form-error">
                        {errorMessage}
                      </div>
                    )}
                    <div className="form-submit">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="btn btn-primary btn-lg btn-signIn"
                        tabIndex="3"
                      >
                        <span
                          className="icon"
                        />
                        <span className="text">Se connecter</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>
      <Footer />
    </div>
  );
}

export default BackofficeLoginPage;
