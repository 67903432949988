import { createSlice } from '@reduxjs/toolkit';
import {
  addWithdrawal,
  fetchWithdrawals,
  updateWithdrawalStatus,
  fetchBackofficeWithdrawals,
} from '../actions/withdrawalActions';
import { WithdrawalStatus } from '../../constants';
import { insertSorted } from '../../utils/arrayUtils';

// Initialisation de l'état initial
const initialState = {
  // Format du retour de l'API pour les retraits
  withdrawals: {
    pending: [],
    ongoing: [],
    ready: [],
    withdrawn: [],
  },
  withdrawal: {},
  loading: false,
  error: null,
};

// Création du slice pour le retrait
const withdrawalSlice = createSlice({
  name: 'withdrawal', // Nom du slice
  initialState, // État initial
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Récupération des retraits en cours
      .addCase(fetchWithdrawals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Récupération des retraits terminée avec succès
      .addCase(fetchWithdrawals.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawals = {
          // Initialisation des colonnes pour que les retraits puissent être ajoutés
          pending: [],
          ongoing: [],
          ready: [],
          ...action.payload,
        };
      })
      // Récupération des retraits en échec
      .addCase(fetchWithdrawals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchBackofficeWithdrawals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBackofficeWithdrawals.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawals = action.payload;
        state.withdrawals = {
          pending: [],
          ongoing: [],
          ready: [],
          withdrawn: [],
          ...action.payload,
        };
      })
      .addCase(fetchBackofficeWithdrawals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Ajout d'un retrait aux retraits en cours
      .addCase(addWithdrawal.fulfilled, (state, action) => {
        const { withdrawal, isBackoffice } = action.payload;
        // Si on est sur le backoffice, on ajoute le retrait dans la colonne correspondante
        // Sinon, on ajoute le retrait dans la colonne des retraits en cours
        const status = isBackoffice ? withdrawal.status : WithdrawalStatus.ONGOING;

        state.withdrawals[status] = insertSorted(
          state.withdrawals[status],
          withdrawal,
          'number',
        );
      })
      .addCase(updateWithdrawalStatus.fulfilled, (state, action) => {
        const { id, status } = action.payload;
        // Retire le retrait de la liste des retraits en cours
        Object.keys(state.withdrawals).forEach((column) => {
          state.withdrawals[column] = state.withdrawals[column]
            .filter((withdrawal) => withdrawal.id !== id);
        });

        // Ajoute le retrait dans la liste des retraits en cours
        // Vérifie si le statut existe dans les retraits
        if (Object.prototype.hasOwnProperty.call(state.withdrawals, status)) {
          // On trie les retraits avant de les ajouter
          state.withdrawals[status] = insertSorted(state.withdrawals[status], action.payload, 'number');
        }
      });
  },
});

export default withdrawalSlice.reducer;
