import { createAsyncThunk } from '@reduxjs/toolkit';
import { postOrderWithdrawal } from '../../services/orderWithdrawalService';

// eslint-disable-next-line import/prefer-default-export
export const createOrderWithdrawal = createAsyncThunk(
  'orderWithdrawal/createOrderWithdrawal',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await postOrderWithdrawal(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  },
);
