import { createBrowserRouter } from 'react-router-dom';
import React from 'react';
import CustomerQueuePage from '../pages/CustomerQueuePage';
import BackofficePage from '../pages/BackofficePage';
import KioskPage from '../pages/KioskPage';
import KioskNotificationPage from '../pages/KioskNotificationPage';
import ConfirmWithdrawalPage from '../pages/ConfirmWithdrawalPage';
import ConfirmNotificationPage from '../pages/ConfirmNotificationPage';
import {
  BACKOFFICE_LOGIN_ROUTE,
  BACKOFFICE_ROUTE,
  confirmWithdrawalRoute,
  confirmWithdrawalNotificationRoute,
  KIOSK_ROUTE,
  KIOSKNOTIF_ROUTE,
  QUEUE_ROUTE,
} from '../constants';
import BackofficeLoginPage from '../pages/BackofficeLoginPage';
import ProtectedRoute from './ProtectedRoute';

const router = createBrowserRouter([
  {
    path: KIOSK_ROUTE,
    element: <KioskPage />,
  },
  {
    path: confirmWithdrawalRoute(':withdrawalNumber'),
    element: <ConfirmWithdrawalPage />,
  },
  {
    path: confirmWithdrawalNotificationRoute(':withdrawalNumber'),
    element: <ConfirmNotificationPage />,
  },
  {
    path: QUEUE_ROUTE,
    element: <CustomerQueuePage />,
  },
  {
    path: BACKOFFICE_ROUTE,
    element: <ProtectedRoute element={<BackofficePage />} />,
  },
  {
    path: BACKOFFICE_LOGIN_ROUTE,
    element: <BackofficeLoginPage />,
  },
  {
    path: KIOSKNOTIF_ROUTE,
    element: <KioskNotificationPage />,
  },
]);

export default router;
