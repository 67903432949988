import React, { useEffect } from 'react';
import Carousel from 'bootstrap/js/dist/carousel';
import landingHowtoCodeSourceTicket from '../../../assets/images/illustrations/landing-howto-codesource-ticket.png';
import landingHowtoCodeSourceBonTelephone from '../../../assets/images/illustrations/landing-howto-codesource-bontelephone.png';
import landingHowtoCodeSourceBonPapier from '../../../assets/images/illustrations/landing-howto-codesource-bonpapier.png';

function CarouselWorkflow() {
  useEffect(() => {
    const carouselCS = document.querySelector('#carouselCodeSources');
    // eslint-disable-next-line no-new
    new Carousel(carouselCS, {
      keyboard: false,
      pause: 'hover',
      ride: 'carousel',
      touch: true,
      wrap: true,
    });
  }, []);
  return (
    <div className="workflow-illustration">
      <h2>Retrouver mon numéro de commande :</h2>
      <div
        id="carouselCodeSources"
        className="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselCodeSources"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Ticket de caisse"
          />
          <button
            type="button"
            data-bs-target="#carouselCodeSources"
            data-bs-slide-to="1"
            aria-label="Bon de comande téléphone"
          />
          <button
            type="button"
            data-bs-target="#carouselCodeSources"
            data-bs-slide-to="2"
            aria-label="Bon de commande papier"
          />
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="4000">
            <h3>Sur mon ticket de caisse</h3>
            <img
              src={landingHowtoCodeSourceTicket}
              alt="Ticket de caisse"
              title="Ticket de caisse"
            />
          </div>
          <div className="carousel-item" data-bs-interval="4000">
            <h3>Sur mon bon de commande téléphone</h3>
            <img
              src={landingHowtoCodeSourceBonTelephone}
              alt="Ticket de caisse"
              title="Bon de comande téléphone"
            />
          </div>
          <div className="carousel-item" data-bs-interval="4000">
            <h3>Sur mon bon de commande papier</h3>
            <img
              src={landingHowtoCodeSourceBonPapier}
              alt="Ticket de caisse"
              title="Bon de commande papier"
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselCodeSources"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselCodeSources"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default CarouselWorkflow;
