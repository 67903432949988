import { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { KIOSK_ROUTE } from '../constants';

const INACTIVITY_TIMEOUT = 15000; // 15 secondes
const EVENTS = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

/**
 * Cette fonction crée un gestionnaire de timeout d'inactivité de l'utilisateur.
 * Par défaut, l'utilisateur est redirigé vers la page du KIOSK après 15 secondes d'inactivité.
 *
 * @param redirectPath Le chemin vers lequel rediriger l'utilisateur
 */
export const useInactivityTimer = (redirectPath = KIOSK_ROUTE) => {
  const navigate = useNavigate();
  const timerRef = useRef(null);

  // Réinitialise le timer.
  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      navigate(redirectPath);
    }, INACTIVITY_TIMEOUT);
  }, [navigate, redirectPath]);

  useEffect(() => {
    const handleUserActivity = () => {
      resetTimer();
    };

    /**
     * Ajoute les écouteurs d'événements.
     */
    EVENTS.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    resetTimer();

    /**
     * Supprime les écouteurs d'événements au démontage du composant.
     * Cela évite les memory leaks.
     */
    return () => {
      EVENTS.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [resetTimer]);
};

export default useInactivityTimer;
