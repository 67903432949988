import React from 'react';
import Logo from '../../assets/images/icons/pg-hdr-logo-ikea.svg';

function Navbar() {
  return (
    <header id="pg-hdr">
      <div className="pg-hdr-wrapper">
        <nav className="navbar navbar-expand-lg">
          <div className="navbar-brand">
            <img src={Logo} alt="IKEA" title="IKEA" />
          </div>
          <div className="navbar-title">Italie Deux - Retrait Parking</div>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
