import React from 'react';

function Footer() {
  return (
    <footer id="pg-ftr">
      <div className="pg-ftr-wrapper">
        <section className="slice bottom">
          <div className="wrapper">
            <div className="slice-content">
              <p className="copyright">&copy; Inter IKEA Systems B.V 1999-2024</p>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
