import { configureStore } from '@reduxjs/toolkit';
import { azureSSOService } from '../services/azureSSOService';
import authReducer from './slices/authSlice';
import withdrawalReducer from './slices/withdrawalSlice';
import withdrawalDetailReducer from './slices/withdrawalDetailSlice';
import orderWithdrawalReducer from './slices/orderWithdrawalSlice';
import orderNotificationReducer from './slices/orderNotifSlice';
import socketReducer from './slices/socketSlice';

const store = configureStore({
  reducer: {
    [azureSSOService.reducerPath]: azureSSOService.reducer,
    auth: authReducer,
    withdrawals: withdrawalReducer,
    withdrawalDetail: withdrawalDetailReducer,
    orderWithdrawal: orderWithdrawalReducer,
    orderNotification: orderNotificationReducer,
    socket: socketReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(azureSSOService.middleware),
});

export default store;
