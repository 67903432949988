import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWithdrawals } from '../store/actions/withdrawalActions';

/**
 * Hook pour récupérer les retraits de la queue client
 *
 * @returns {{loading: *, error: *, withdrawals: *}} -
 * Les retraits, l'état de chargement et les erreurs
 */
const useFetchWithdrawals = () => {
  const dispatch = useDispatch();
  const { withdrawals, loading, error } = useSelector((state) => state.withdrawals);

  useEffect(() => {
    dispatch(fetchWithdrawals());
  }, [dispatch]);

  return { withdrawals, loading, error };
};

export default useFetchWithdrawals;
