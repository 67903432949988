import PropTypes from 'prop-types';

export const errorProps = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

export const errorDefaultProps = {
  error: null,
};
