const ENDPOINTS = {
  WITHDRAWALS: '/api/withdrawals',
  ORDER_WITHDRAWALS: '/api/order-withdrawals',
  backofficeWithdrawalStatus: (withdrawalId) => `/api/backoffice/withdrawals/${withdrawalId}/status`,
  BACKOFFICE_WITHDRAWAL: '/api/backoffice/withdrawals',
  backofficeWithdrawalDetail: (withdrawalId) => `/api/backoffice/withdrawals/${withdrawalId}`,
  AZURE_SSO_AUTH: '/api/auth/azure',
  AZURE_SSO_LOGOUT: '/api/auth/azure/logout',
  // Pour updater le client en ready
  backofficeWithdrawalUpdate: (withdrawalId) => `/api/withdrawals/${withdrawalId}/update`,
};

export default ENDPOINTS;
