import { createSlice } from '@reduxjs/toolkit';
import { azureSSOService } from '../../services/azureSSOService';

// Vérifie si des informations de connexion sont stockées dans le localStorage
const storedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { mail: null };
const storedToken = localStorage.getItem('token') || null;

// Définit l'état initial du slice avec les informations de connexion stockées ou à null
const initialState = {
  user: storedUser,
  token: storedToken,
  // Si le token existe, alors !!storedToken renvoie true, sinon false
  isAuthenticated: !!storedToken,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Enregistre les informations de connexion dans le state et dans le localStorage
    setCredentials: (state, action) => {
      const { user, access_token: token } = action.payload.data;
      state.user = user;
      state.token = token;
      state.isAuthenticated = true;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
    },
    // Supprime les informations de connexion du state et du localStorage
    clearCredentials: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(azureSSOService.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.token;
      state.isAuthenticated = true;
    });
    builder.addMatcher(azureSSOService.endpoints.logout.matchFulfilled, (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
    });
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;
