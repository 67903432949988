import { Spinner } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

function Loader({ className }) {
  return (
    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className={className} />
  );
}

export default Loader;

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};
