import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { KIOSK_ROUTE } from '../constants';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import { useInactivityTimer } from '../hooks/useInactivityTimer';

/* eslint-disable jsx-a11y/anchor-is-valid */
function ConfirmNotificationPage() {
  const { withdrawalNumber } = useParams();

  useInactivityTimer();

  return (
    <div id="pg" className="kiosk">
      <Navbar />
      <main id="pg-main">
        <section className="slice">
          <div className="slice-wrapper">
            <div className="slice-content">
              <section className="slice workflow clientReady workflow-success">
                <div className="slice-wrapper">
                  <header className="slice-header">
                    <h1>Retirer mon achat au Parking</h1>
                  </header>
                  <div className="slice-content">
                    <div className="workflow-illustration">
                      <img src="/illustrations/success.avif" alt="Retrait Parking" title="Retrait Parking" />
                    </div>
                    <div className="workflow-content">
                      <h2>
                        <span className="icon"><img src="/icons/icon-checkmark-white.svg" alt="OK" title="OK" /></span>
                        Nous préparons votre commande
                      </h2>
                      <div className="intro">
                        <p>
                          <b>
                            Votre commande va être préparée et présentée
                            <br />
                            à la zone de retrait parking.
                          </b>
                          <br />
                          Dirigez-vous vers la zone de retrait parking
                          muni(e) du code de retrait ci-dessous.
                        </p>
                        <div className="code">
                          <p>Votre code de retrait :</p>
                          <div className="withdrawalCode">{withdrawalNumber}</div>
                        </div>
                        <p>
                          Suivez en permanence la progression de la préparation de votre commande
                          sur les écrans à disposition.
                        </p>
                      </div>
                      <div className="howto parking">
                        <p>
                          Rendez-vous au retrait marchandises
                          <b>allée A9 du parking -1</b>
                          avec votre véhicule.
                        </p>
                      </div>
                      <div className="actions">
                        <Link to={KIOSK_ROUTE} title="Retour à l'accueil" className="btn btn-secondary btn-lg home">
                          <span className="icon" />
                          <span className="text">Retour à l&apos;accueil</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default ConfirmNotificationPage;
