import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Affiche un overlay indiquant que la page est en cours de chargement.
 *
 * @param delay le délai avant l'affichage de l'overlay en millisecondes.
 * @returns {React.JSX.Element|null} L'overlay de chargement ou null si le délai n'est pas écoulé.
 */
function OverlayLoading({ delay = 500 }) {
  const [showOverlay, setShowOverlay] = useState(false);

  // Chargement de l'overlay après un certain délai pour éviter les flashs
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverlay(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  if (!showOverlay) return null;

  return (
    <div className="overlay-loading">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Chargement...</span>
      </div>
      <p>Veuillez patienter pendant le chargement...</p>
    </div>
  );
}

export default OverlayLoading;

OverlayLoading.propTypes = {
  delay: PropTypes.number,
};

OverlayLoading.defaultProps = {
  delay: 500,
};
