import PropTypes from 'prop-types';

export const withdrawalsProps = {
  withdrawals: PropTypes.shape({
    pending: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
    })),
    ongoing: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
    })),
    ready: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
    })),
    withdrawn: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
    })),
  }),
};

export const withdrawalsDefaultProps = {
  withdrawals: {
    pending: [],
    ongoing: [],
    ready: [],
    withdrawn: [],
  },
};
